<template>
  <div>
    <div class="form-avatar">
      <UpdateAvatar v-if="asyncData" :image="image" @crop-image="cropImage" />
    </div>
    <div class="form-avatar-skeleton" v-if="!asyncData">
      <ion-list class="ion-no-padding" lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-skeleton-text class="skeleton-text" animated style="width: 100%"></ion-skeleton-text>
      </ion-list>
    </div>

    <div class="form-group">
      <ion-list class="ion-no-padding" lines="none">
        <ion-item
          :class="
            form.firstName.isError ? 'item-danger' : form.firstName.value ? 'item-primary' : 'item-medium'
          "
        >
          <div class="form-control">
            <ion-label
              :class="form.firstName.isError ? 'text-danger' : form.firstName.value ? 'text-primary' : ''"
              >{{ $t('first_name') }}</ion-label
            >
            <ion-input
              v-model="form.firstName.value"
              @ionInput="handleValidateForm('firstName')"
              class="input"
            />
          </div>
        </ion-item>
      </ion-list>
      <ion-text>
        <div v-if="form.firstName.isError" class="py-1 fs-12px text-danger form-error">
          {{
            form.firstName.value?.length === 0
              ? $t('first_name_cannot_be_empty')
              : $t('first_name_must_be_at_least_2_characters')
          }}
        </div>
      </ion-text>
    </div>
    <div class="form-group">
      <ion-list
        :class="['ion-no-padding', form.lastName.value ? 'item-primary' : 'item-medium']"
        lines="none"
      >
        <ion-item>
          <div class="form-control">
            <ion-label :class="form.lastName.value ? 'text-primary' : ''">{{ $t('last_name') }}</ion-label>
            <ion-input v-model="form.lastName.value" class="input" />
          </div>
        </ion-item>
      </ion-list>
    </div>
    <div class="form-group">
      <ion-list
        :class="['ion-no-padding', form.birthDate.value ? 'item-primary' : 'item-medium']"
        lines="none"
      >
        <ion-item @click="handleRenderComponent">
          <div class="form-control">
            <ion-label :class="form.birthDate.value ? 'text-primary' : ''">{{ $t('birth_date') }}</ion-label>
            <ion-text>{{ dayjs(form.birthDate.value).format('DD MMM YYYY') }}</ion-text>
            <ModalDateTime
              v-if="isShowModalDateTime"
              :initialization_time="form.birthDate.value"
              @handleSendTimeChange="handleSendTimeChange"
            ></ModalDateTime>
          </div>
        </ion-item>
      </ion-list>
    </div>
    <div class="form-group">
      <div class="form-group-radio">
        <ion-label :class="form.gender.value ? 'text-primary' : ''">{{ $t('gender') }}</ion-label>
        <ion-list lines="none" mode="md">
          <ion-radio-group v-model="form.gender.value">
            <div class="d-flex">
              <ion-item class="ion-no-border">
                <ion-label>{{ $t('male') }}</ion-label>
                <ion-radio slot="start" value="m"></ion-radio>
              </ion-item>

              <ion-item class="ion-no-border ml-6">
                <ion-label>{{ $t('female') }}</ion-label>
                <ion-radio slot="start" value="f"></ion-radio>
              </ion-item>
            </div>
            <div class="d-flex">
              <ion-item class="ion-no-border">
                <ion-label>{{ $t('other') }}</ion-label>
                <ion-radio slot="start" value="o"></ion-radio>
              </ion-item>
            </div>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>

    <div class="form-group">
      <div
        :class="[
          'form-phone',
          form.mobile.isError ? 'item-danger' : form.mobile.value ? 'item-primary' : 'item-medium'
        ]"
      >
        <div class="phone-input">
          <ion-label :class="form.mobile.isError ? 'text-danger' : form.mobile.value ? 'text-primary' : ''">
            {{ $t('phone') }}
          </ion-label>
          <vue-tel-input
            v-model="form.mobile.value"
            mode="national"
            enabledCountryCode
            enabledFlags
            validCharactersOnly
            pattern="[0-9]*$"
            styleClasses="no-border	no-box-shadow mt-2"
            ref="phone-field"
            inputId="phone-field"
            disabledFetchingCountry
            :onlyCountries="getPreferedCountryCode()"
            :autoDefaultCountry="false"
            :defaultCountry="defaultCountry"
            :autoFormat="false"
            @on-input="onlyNumber"
            @input="onPhoneChanged"
            @keypress="blockDecimalInput"
            :inputOptions="{
              type: 'tel',
              maxlength: 16,
              placeholder: $t('mobile_number')
            }"
            @country-changed="countryChange"
          />
        </div>
      </div>
      <ion-text>
        <div v-if="form.mobile.isError" class="py-1 fs-12px text-danger form-error">
          {{ $t('phone_number_cannot_be_empty') }}
        </div>
      </ion-text>
    </div>
    <div class="form-group">
      <ion-list class="ion-no-padding" lines="none">
        <ion-item
          :class="form.email.isError ? 'item-danger' : form.email.value ? 'item-primary' : 'item-medium'"
        >
          <div class="form-control">
            <ion-label :class="form.email.isError ? 'text-danger' : form.email.value ? 'text-primary' : ''">{{
              $t('email')
            }}</ion-label>
            <ion-input v-model="form.email.value" @ionInput="handleValidateForm('email')" class="input" />
          </div>
        </ion-item>
      </ion-list>
      <ion-text>
        <div v-if="form.email.isError" class="py-1 fs-12px text-danger form-error">
          {{
            form.email.value?.length === 0
              ? $t('updateProfile.email_cannot_be_empty')
              : $t('this_field_must_be_a_valid_email')
          }}
        </div>
      </ion-text>
    </div>
    <div class="form-btn">
      <ion-button color="primary" mode="md" @click="handleSaveChanges">{{ $t('save_changes') }}</ion-button>
    </div>

    <ion-loading
      mode="ios"
      :is-open="isOpenRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
    <ion-toast
      mode="ios"
      color="dark"
      :is-open="isOpenRefToast"
      :message="$t('saved_successfully')"
      :duration="2000"
      position="top"
      @didDismiss="setOpenToast(false)"
    >
    </ion-toast>
  </div>
</template>

<script>
import ModalDateTime from '@/components/DateTime/index.vue';
import { apolloClient } from '@/main';
import {
  findObjectByKey,
  getDefaultCountryCode,
  getPreferedCountryCode,
  getRegexEmail
} from '@/modules/b2b/services/libs/helper';
import { getCurrentUser, saleUpdateProfile } from '@/modules/sale/services/graphql';
import CleverTap from '@/services/shared/helper/clevertap.js';
import { alertController } from '@ionic/vue';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { ref } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import UpdateAvatar from './UpdateAvatar.vue';

export default {
  components: {
    UpdateAvatar,
    VueTelInput,
    ModalDateTime
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    const isOpenRefToast = ref(false);
    const setOpenToast = async (state) => (isOpenRefToast.value = state);
    const isShowModalDateTime = ref(false);
    return {
      getPreferedCountryCode,
      isOpenRef,
      setOpen,
      isOpenRefToast,
      setOpenToast,
      dayjs,
      isShowModalDateTime
    };
  },
  inject: ['$storage'],
  data() {
    return {
      form: {
        firstName: {
          value: '',
          isError: false
        },
        mobile: {
          value: '',
          isError: false
        },
        email: {
          value: '',
          isError: false
        },
        lastName: {
          value: ''
        },
        gender: {
          value: ''
        },
        birthDate: {
          value: ''
        }
      },
      defaultCountry: '',
      countryCode: '',

      isOpenModal: false,
      asyncData: false,
      oldPhoneNumber: '',
      oldImage: null,
      findObjectByKey
    };
  },
  async created() {
    await this.handleGetCurrentUser();
    const fullPhoneNumber = this.form.mobile.value;
    this.form.mobile.value =
      fullPhoneNumber.indexOf(' ') > -1
        ? fullPhoneNumber.split(' ')[1]
        : fullPhoneNumber.length > 8
        ? fullPhoneNumber.substring(2, fullPhoneNumber.length)
        : fullPhoneNumber;
    this.$nextTick(() => {
      this.splitPhoneAndCountryCode(fullPhoneNumber, this.form.mobile.value);
    });
  },
  methods: {
    handleRenderComponent() {
      this.isShowModalDateTime = false;
      this.$nextTick(() => {
        this.isShowModalDateTime = true;
      });
    },
    async handleGetCurrentUser() {
      try {
        const { data } = await apolloClient.query({
          query: getCurrentUser
        });
        const profile = data.me;
        this.image = profile.image;
        this.form.firstName.value = profile.first_name;
        this.form.lastName.value = profile.last_name;
        this.form.gender.value = profile.gender;
        this.form.mobile.value = profile.mobile;
        this.form.birthDate.value = profile.birth_date;
        this.form.email.value = profile.email;
        this.asyncData = true;
        this.oldPhoneNumber = this.form.mobile.value;
        this.oldImage = '';
      } catch (e) {
        this.presentAlert(e);
      }
    },
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.form.mobile.value = input;
    },

    blockDecimalInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    validateForm(params) {
      try {
        if (params === 'firstName') {
          const lengthFirstName = this.form.firstName.value?.length;
          lengthFirstName === 0 || lengthFirstName < 2
            ? (this.form.firstName.isError = true)
            : (this.form.firstName.isError = false);
        } else if (params === 'phone') {
          this.form.mobile.value?.length === 0
            ? (this.form.mobile.isError = true)
            : (this.form.mobile.isError = false);
        } else {
          if (this.form.email?.value.length === 0) {
            this.form.email.isError = true;
          } else if (!getRegexEmail().test(this.form.email.value)) {
            this.form.email.isError = true;
          } else {
            this.form.email.isError = false;
          }
        }
      } catch (e) {
        this.presentAlert(e);
      }
    },
    handleValidateForm: debounce(async function (params) {
      this.validateForm(params);
    }, 500),
    handleSaveChanges: debounce(async function () {
      try {
        await this.setOpen(true);
        const params = ['firstName', 'phone', 'email'];
        for (const value of params) {
          this.validateForm(value);
        }
        let isUpdate = true;
        for (let i in this.form) {
          if (this.form[i].isError === true) {
            isUpdate = false;
            break;
          }
        }
        if (isUpdate) {
          const items = {
            firstName: this.form.firstName.value,
            lastName: this.form.lastName.value,
            gender: this.form.gender.value,
            email: this.form.email.value,
            birthDate: this.form.birthDate.value,
            mobile: `${this.countryCode + this.form.mobile.value}`,
            ...(this.oldImage ? { image: this.image } : {})
          };
          const { data } = await apolloClient.query({
            query: saleUpdateProfile,
            variables: items
          });
          CleverTap.updateUserProfile(items);
          await this.setOpenToast(true);
          setTimeout(() => {
            if (data.saleUpdateProfile) {
              this.$router.push({
                path: '/sale/account',
                query: { isRenderProfile: true }
              });
            } else {
              this.$router.push('/sale/account');
            }
          }, 500);
        }
      } catch (error) {
        this.presentAlert(error);
      } finally {
        await this.setOpen(false);
      }
    }, 500),

    handleCloseModal() {
      this.isOpenModal = false;
    },

    cropImage(cropImg) {
      this.image = cropImg;
      this.oldImage = cropImg;
      this.cropImg = cropImg;
    },
    countryChange(val) {
      this.defaultCountry = val.iso2;
      this.countryCode = val.dialCode || getDefaultCountryCode();
    },
    splitPhoneAndCountryCode(fullPhoneNumber, phone) {
      this.countryCode =
        fullPhoneNumber.indexOf(' ') > -1
          ? fullPhoneNumber.split(' ')[0]
          : fullPhoneNumber.length > 8
          ? fullPhoneNumber.substring(0, 2)
          : getDefaultCountryCode();
      const country = this.findObjectByKey(
        this.$refs['phone-field'].allCountries, // List country
        'dialCode', // Key to find
        this.countryCode // value to find
      );

      this.defaultCountry = country ? country.iso2 : null;
      this.$refs['phone-field'].choose(this.defaultCountry);
      this.form.mobile.value = phone;
    },
    onPhoneChanged() {
      this.firstChange['mobile'] = true;
    },
    async presentAlert(message) {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: message,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    handleErrorEmit(message) {
      this.presentAlert(message);
    },
    handleOpenLoading(params) {
      this.setOpen(params);
    },
    async handleSuccessVerify() {
      this.isOpenModal = false;
      await this.setOpenToast(true);
      this.handleGetConnectPerson();
    },
    handleSendTimeChange(e) {
      this.form.birthDate.value = String(e);
    }
  },
  watch: {
    'form.mobile.value': debounce(function () {
      this.validateForm('phone');
    }, 500)
  }
};
</script>

<style src="../styles/UpdateProfile.scss" lang="scss" scoped></style>
